import { Button, DialogContent, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { MarkOptional } from "ts-essentials";
import { DatasetPublic, RedirectRule } from "@triply/utils/Models.js";
import { validation } from "@triply/utils-private";
import { Dialog, FormField, LoadingButton, MaterialRadioButtonGroup } from "#components/index.ts";
import SearchDataset from "#components/SearchDataset/index.tsx";

const prefixValidator = validation.toStringValidator(validation.redirectPrefixValidations);
const regexValidator = validation.toStringValidator(validation.redirectRegexValidations);
const requiredValidator = validation.toStringValidator([validation.required]);

interface Props {
  initialData?: FormData;
  onCancel: () => void;
  onSubmit: (rule: FormData) => void;
}

interface FormData extends MarkOptional<RedirectRule, "id"> {}

const RedirectForm: React.FC<Props> = ({ onCancel, initialData, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting },
  } = useForm<FormData>({ defaultValues: initialData });
  const updateing = initialData?.id !== undefined;

  return (
    <Dialog open onClose={onCancel} maxWidth="md" fullWidth title={`${updateing ? "Edit" : "Create"} rule`}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField label="Matching method" className="mt-4 mb-5">
            <Controller
              control={control}
              name="matchingMethod"
              defaultValue="prefix"
              rules={{
                validate: (value) => requiredValidator(value),
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <RadioGroup {...field}>
                    <FormControlLabel value="prefix" label="Prefix" control={<Radio color="primary" />} />
                    <FormControlLabel value="regexp" label="Regular Expression" control={<Radio color="primary" />} />
                    <FormHelperText error={!!error}>{error?.message}</FormHelperText>
                  </RadioGroup>
                );
              }}
            />
          </FormField>
          <FormField label="Pattern to match" className="mb-5">
            <Controller
              name="match"
              rules={{
                required: "This field is required",
                validate: (value, formValues) => {
                  return (
                    requiredValidator(value) ||
                    (formValues.matchingMethod === "regexp" ? regexValidator(value) : prefixValidator(value))
                  );
                },
              }}
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => {
                return <TextField {...field} fullWidth error={!!error} helperText={error?.message} />;
              }}
            />
          </FormField>
          <FormField label="Dataset to redirect to" className="mb-5">
            <Controller
              control={control}
              name="toDataset"
              rules={{
                required: "No dataset selected",
              }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <SearchDataset {...field} value={(field.value as DatasetPublic) || null} error={error?.message} />
                );
              }}
            />
          </FormField>
          <div className={getClassName("form-group mt-5")}>
            <LoadingButton color="secondary" disabled={!isDirty || isSubmitting} type="submit" loading={isSubmitting}>
              {updateing ? "Update" : "Create"} rule
            </LoadingButton>

            <Button onClick={onCancel} className="mx-3" variant="text">
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RedirectForm;
