import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Autocomplete,
  AutocompleteProps,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import getClassName from "classnames";
import { debounce } from "lodash-es";
import { stringToTerm } from "rdf-string";
import * as React from "react";
import { validation } from "@triply/utils-private";
import { FontAwesomeIcon, Highlight } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../../helpers/hooks/useFetch";
import { formValuesToQueryVar, VariableType } from "./QueryVariables";
import * as styles from "./style.scss";

const MAX_ALLOWED_VALUES_LENGTH = 50;

interface Props
  extends Omit<
    AutocompleteProps<string, false, true, true>,
    "options" | "renderInput" | "onChange" | "freeSolo" | "value" | "disableClearable"
  > {
  datasetPath: string;
  variableType: VariableType;
  datatype?: string;
  language?: string;
  error?: string;
  onChange: (value: string[]) => void;
  value: string[];
}

const AllowedValues = React.forwardRef<unknown, Props>(
  ({ datasetPath, variableType, datatype, language, error, onChange, value, ...props }, ref) => {
    const [activeId, setActiveId] = React.useState<number | string | null>(null);

    const [searchString, setSearchString] = React.useState<string>("");
    const [textValue, setTextValue] = React.useState<string>("");
    const [searchedValue, setSearchedValue] = React.useState("");
    const constructUrlToApi = useConstructUrlToApi();
    const debouncedSetSearchString = React.useMemo(() => debounce(setSearchString, 250), [setSearchString]);
    const termsPath = constructUrlToApi({
      pathname: `/datasets/${datasetPath}/terms`,
      query: {
        q: `${variableType !== "NamedNode" ? '"' : ""}${searchString}`,
        dataType:
          variableType === "TypedLiteral" && datatype
            ? datatype
            : variableType === "StringLiteral"
              ? "http://www.w3.org/2001/XMLSchema#string"
              : undefined,
        languageTag: variableType === "LanguageStringLiteral" && language ? language : undefined,
        termType: variableType === "NamedNode" ? "NamedNode" : "Literal",
      },
    });
    const { data, error: fetchError } = useFetch<string[]>(
      termsPath,
      {
        interceptors: {
          response: async ({ response }) => {
            const originalSearch = new URL(response.url).searchParams.get("q");
            if (originalSearch) setSearchedValue(originalSearch[0] === '"' ? originalSearch.slice(1) : originalSearch);
            return response;
          },
        },
      },
      [termsPath],
    );
    const options =
      Array.isArray(data) && !fetchError
        ? data?.map((termString) => stringToTerm(termString).value).filter((option) => !value.includes(option))
        : [];
    const validator = React.useMemo(
      () =>
        validation.toStringValidator(
          validation.getQueryVarValidations(
            formValuesToQueryVar({
              name: "newValue",
              variableType: variableType,
              datatype: datatype,
              language: language,
            }),
          ),
        ),
      [datatype, language, variableType],
    );
    const exceededMaxLength =
      value.length >= MAX_ALLOWED_VALUES_LENGTH
        ? `Only a maximum of ${MAX_ALLOWED_VALUES_LENGTH} values is allowed.`
        : undefined;
    const validationError = textValue !== "" ? validator(textValue) : undefined;
    const textError = error || validationError || exceededMaxLength;
    const addNewLine = React.useCallback(
      (newValue: string) => {
        if (newValue === "") return;
        if (value.includes(newValue)) return;
        if (validator(newValue)) return;

        onChange([...value, newValue]);
      },
      [onChange, value, validator],
    );
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    );

    return (
      <div className={styles.allowedValuesWrapper}>
        <Autocomplete<string, false, true, true>
          {...props}
          ref={ref}
          disabled={!!exceededMaxLength}
          freeSolo={true}
          disableClearable
          options={options}
          inputValue={textValue}
          onChange={(e, value) => {
            e.preventDefault();
            e.stopPropagation();
            if (value) {
              addNewLine(value || "");
              setTextValue("");
            }
          }}
          renderInput={({ inputProps, InputProps, ...rest }) => (
            <TextField
              {...rest}
              fullWidth
              error={!!textError}
              helperText={textError}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTextValue(e.target.value);
                debouncedSetSearchString(e.target.value);
              }}
              inputProps={{
                ...inputProps,
                width: typeof inputProps.width === "number" ? `${inputProps.width}px` : inputProps.width,
                height: typeof inputProps.height === "number" ? `${inputProps.height}px` : inputProps.height,
              }}
              InputProps={{
                ...InputProps,
                onChange: (e) => {
                  setTextValue(e.target.value);
                  debouncedSetSearchString(e.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="Add value"
                      title="Add value"
                      onClick={(_) => {
                        addNewLine(inputProps.value as string);
                        setTextValue("");
                      }}
                    >
                      <FontAwesomeIcon icon="plus" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          filterOptions={(x) => x}
          renderOption={(props, option) => {
            const displayValue = option.trim().split("\n")[0];
            const showEllipsis = option.trim().length > displayValue.length;

            if (!option) return null;
            return (
              <ListItem key={option} {...props} className={getClassName(styles.autocompleteItem, props.className)}>
                <ListItemText>
                  <Highlight fullText={displayValue} highlightedText={searchedValue} />
                  {showEllipsis ? "…" : null}
                </ListItemText>
              </ListItem>
            );
          }}
        />
        <DndContext
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          sensors={sensors}
          onDragCancel={(event) => {
            setActiveId(null);
          }}
          onDragEnd={(event) => {
            const { active, over } = event;
            setActiveId(null);
            if (over?.id === undefined || over?.id === null) return;
            if (active.id !== over.id) {
              const newValue = [...value];
              newValue.splice(value.indexOf(active.id as string), 1);
              newValue.splice(value.indexOf(over.id as string), 0, active.id as string);
              onChange(newValue);
            }
          }}
          onDragStart={(event) => {
            setActiveId(event.active.id);
          }}
        >
          <SortableContext items={value} strategy={verticalListSortingStrategy}>
            <List dense>
              {value.map((item) => (
                <AllowedValue
                  key={item}
                  value={item}
                  invalid={validator(value)}
                  onRemove={(valueToRemove) => onChange(value.filter((val) => val !== valueToRemove))}
                />
              ))}
            </List>
          </SortableContext>
          <DragOverlay>
            {activeId && <AllowedValue value={activeId as string} className={styles.dragging} />}
          </DragOverlay>
        </DndContext>
      </div>
    );
  },
);

export default AllowedValues;

interface AllowedValueProps {
  value: string;
  onRemove?: (value: string) => void;
  invalid?: string | undefined;
  className?: string;
}

const AllowedValue: React.FC<AllowedValueProps> = ({ onRemove, invalid, value, className }) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: value,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      dense
      ref={setNodeRef}
      style={style}
      className={getClassName(className, styles.listItem, { ["invisible"]: isDragging })}
      secondaryAction={
        onRemove && (
          <ListItemSecondaryAction>
            <IconButton aria-label="Remove value from list" onClick={() => onRemove(value)} size="small">
              <FontAwesomeIcon icon="times" />
            </IconButton>
          </ListItemSecondaryAction>
        )
      }
    >
      <ListItemIcon>
        <IconButton
          ref={setActivatorNodeRef}
          {...listeners}
          {...attributes}
          className={getClassName(styles.enabledDragHandle)}
          size="small"
        >
          <FontAwesomeIcon icon="bars" />
        </IconButton>
      </ListItemIcon>
      <ListItemText>
        {value}
        {!!invalid && (
          <span aria-label={invalid} title={invalid} className="mx-3">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </span>
        )}
      </ListItemText>
    </ListItem>
  );
};
