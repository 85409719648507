import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { HierarchyProperty } from "./SkosTreeContext";

const factory = factories.compliant;

const PREFIXES = `
  prefix skos: <http://www.w3.org/2004/02/skos/core#>
  prefix dct: <http://purl.org/dc/terms/>
  prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
  prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
  prefix triply: <https://triplydb.com/Triply/function/>
`;

/**
 * Main query, fetches a sorted list of top concepts with their scheme.
 */
export const sparqlFetchTopLevelConcepts = (filterSchemes: string[]) => `
  # sparqlFetchTopLevelConcepts
  ${PREFIXES}

  select distinct ?concept ?conceptLabel ?scheme where {
    values ?scheme { ${filterSchemes[0] ? termToString(factory.namedNode(filterSchemes[0])) : ""} }
    ?concept skos:topConceptOf ?scheme .
    bind(triply:firstLabel(?concept) as ?conceptLabel)
  }
  order by asc(?conceptLabel)
`;

export const allSchemesQuery = () => `
  # allSchemesQuery
    ${PREFIXES}

  select distinct ?conceptScheme (triply:firstLabel(?conceptScheme) as ?label) where {
    ?conceptScheme a/rdfs:subClassOf* skos:ConceptScheme .
  }
  order by asc(lcase(?label))

`;

export const allHierarchyPropertiesQuery = () => `
  # allHierarchyPropertiesQuery
    ${PREFIXES}

  select ?property ?direction where {
    {
      values ?broader {
        skos:broader
        skos:broadMatch
      }
      ?property rdfs:subPropertyOf* ?broader
      bind("broader" as ?direction)
    } union {
      values ?narrower {
        skos:narrower
        skos:narrowMatch
      }
      ?property rdfs:subPropertyOf* ?narrower
      bind("narrower" as ?direction)
    }
  }
`;

export const breadcrumbPartsQuery = (
  resource: string,
  filterSchemes: string[],
  hierarchyProperties: HierarchyProperty[],
) => {
  const filterSchemesIris = filterSchemes.map((scheme) => termToString(factory.namedNode(scheme))).join(", ");

  const BROADER = hierarchyProperties
    .map((p) => `${p.direction === "narrower" ? "^" : ""}${termToString(factory.namedNode(p.property))}`)
    .join("|");
  const BROADER_ZERO_OR_MORE = `(${BROADER})*`;

  return `
  # breadcrumbParts
  ${PREFIXES}

  select ?id ?parent
  where {
    bind(<${resource}> as ?this)
    ?this ${BROADER_ZERO_OR_MORE} ?id .
    ?id skos:inScheme ?scheme
    filter(?scheme in (${filterSchemesIris}))
    optional {
      ?id ${BROADER} ?parent.
      ?parent skos:inScheme ?parentScheme .
      filter(?parentScheme in (${filterSchemesIris}))
    }
  }
  group by ?id ?parent

`;
};

/**
 * Fetches wether a skos concept is expandable / has sub terms.
 */
export const sparqlFetchExpandable = (
  values: string,
  filterSchemes: string[],
  hierarchyProperties: HierarchyProperty[],
) => `
  # sparqlFetchExpandable
  ${PREFIXES}

  select ?concept ?isExpandable where {
    values ?concept { ${values} }
    values ?scheme { ${filterSchemes.map((scheme) => termToString(factory.namedNode(scheme))).join(" ")} }

    bind(exists {
      ?concept ${hierarchyProperties.map((p) => `${p.direction === "broader" ? "^" : ""}${termToString(factory.namedNode(p.property))}`).join("|")} ?childConcept .
      ?childConcept skos:inScheme ?childScheme
      FILTER (?childScheme IN ( ${filterSchemes.map((scheme) => termToString(factory.namedNode(scheme))).join(", ")} ))
    } as ?isExpandable)

    ?concept skos:inScheme ?scheme .
  }
`;

/**
 * Fetches tree items, children from a parent.
 */
export const sparqlFetchTreeChild = (
  concept: string,
  filterSchemes: string[],
  hierarchyProperties: HierarchyProperty[],
) => `
  # sparqlFetchTreeChild
  ${PREFIXES}

  select distinct ?concept ?conceptLabel ?scheme ?isExpandable where {
    bind (<${concept}> as ?parent)
    values ?scheme { ${filterSchemes.map((scheme) => termToString(factory.namedNode(scheme))).join(" ")} }
    ?concept ${hierarchyProperties.map((p) => `${p.direction === "narrower" ? "^" : ""}${termToString(factory.namedNode(p.property))}`).join("|")} ?parent .

    bind(exists {
      ?concept ${hierarchyProperties.map((p) => `${p.direction === "broader" ? "^" : ""}${termToString(factory.namedNode(p.property))}`).join("|")} ?childConcept .
      ?childConcept skos:inScheme ?childScheme
      FILTER (?childScheme IN ( ${filterSchemes.map((scheme) => termToString(factory.namedNode(scheme))).join(", ")}) )
    } as ?isExpandable)

    ?concept skos:inScheme ?scheme .
    bind(triply:firstLabel(?concept) as ?conceptLabel)
  }
  order by asc(?conceptLabel)
`;

export const conceptSchemeHierarchy = () => `
  # conceptSchemeHierarchy
  ${PREFIXES}

  select distinct ?parentConceptScheme ?childConceptScheme  {
    ?broadMatch rdfs:subPropertyOf* skos:broadMatch .
    ?childConcept skos:inScheme ?childConceptScheme; ?broadMatch ?parentConcept.
    ?parentConcept skos:inScheme ?parentConceptScheme.
    filter(?childConceptScheme != ?parentConceptScheme)
  }
`;
