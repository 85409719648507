import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Alert, Button, IconButton } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { UnreachableCaseError } from "ts-essentials";
import { Models } from "@triply/utils";
import { FontAwesomeIcon } from "#components/index.ts";
import { getQueryIcon } from "#helpers/FaIcons.tsx";
import { useConfirmation } from "#helpers/hooks/confirmation.tsx";
import useAcl from "../../helpers/hooks/useAcl.ts";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import { updateQuery } from "../../reducers/queries.ts";
import { storyElementToUpdateObj, updateStory } from "../../reducers/stories.ts";
import { LocationState } from "./index.tsx";
import Paragraph from "./Paragraph.tsx";
import Query from "./Query.tsx";
import { isStricterThan } from "./utils.ts";
import * as styles from "./StoryElement.scss";

const Element: React.FC<{
  storyElement: Models.StoryElement;
  editMode?: boolean;
  handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
  handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
}> = ({ storyElement, ...rest }) => {
  switch (storyElement.type) {
    case "query":
      return <Query {...rest} storyElement={storyElement} />;
    case "paragraph":
      return <Paragraph storyElement={storyElement} />;
    default:
      throw new UnreachableCaseError(storyElement);
  }
};

const StoryElement: React.FC<{
  storyElement: Models.StoryElement;
  story: Models.Story;
  editMode?: boolean;
  handleStoryElementHeightChange: (storyElementId: string, width: Models.StoryElementHeight) => void;
  handleStoryElementWidthChange: (storyElementId: string, width: Models.StoryElementWidth) => void;
}> = ({ storyElement, story, editMode, handleStoryElementHeightChange, handleStoryElementWidthChange }) => {
  const history = useHistory<LocationState>();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: storyElement.id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className={getClassName({ [styles.paragraphWrapper]: storyElement.type === "paragraph" })}
      style={style}
    >
      <div
        id={storyElement.id}
        className={getClassName(styles.storyElement, "pb-3 mt-7 mb-4", {
          [styles.editable]: editMode,
          [styles.dragging]: isDragging,
        })}
      >
        {editMode && (
          <div className={getClassName("flex center pr-3 pb-2", styles.storyElementToolbar)}>
            <IconButton
              ref={setActivatorNodeRef}
              {...listeners}
              {...attributes}
              size="small"
              className={styles.dragHandle}
            >
              <FontAwesomeIcon icon="bars" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="Edit story element"
              title="Edit story element"
              onClick={() =>
                history.push({
                  search: location.search,
                  state: { storyElementEditModalShown: storyElement.id, preserveScrollPosition: true },
                })
              }
            >
              <FontAwesomeIcon icon="pencil" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="Delete story element"
              title="Delete story element"
              onClick={(e) => {
                e.stopPropagation();
                confirm({
                  description: "Are you sure you want to delete this story element?",
                  title: "Delete story element?",
                  actionLabel: "Delete",
                  onConfirm: () => {
                    if (story) {
                      return dispatch<typeof updateStory>(
                        updateStory(story, {
                          content: story.content.filter((e) => e.id !== storyElement.id).map(storyElementToUpdateObj),
                        }),
                      );
                    }
                  },
                });
              }}
            >
              <FontAwesomeIcon icon="times" />
            </IconButton>
          </div>
        )}
        {editMode && storyElement.type === "query" && (
          <div className={getClassName("my-2", styles.warningElement)}>
            <Warning storyAccessLevel={story?.accessLevel} query={storyElement?.query} />
          </div>
        )}
        <Element
          storyElement={storyElement}
          editMode={editMode}
          handleStoryElementWidthChange={handleStoryElementWidthChange}
          handleStoryElementHeightChange={handleStoryElementHeightChange}
        />
      </div>
    </div>
  );
};

export default StoryElement;

export const MockElement: React.FC<{ storyElement: Models.StoryElement }> = ({ storyElement }) => {
  return (
    <div className={getClassName(styles.mockElement, { [styles.paragraphWrapper]: storyElement.type === "paragraph" })}>
      <div id={storyElement.id} className={getClassName(styles.storyElement, "pb-3 mt-7 mb-4", {})}>
        <div className={getClassName("flex center pr-3 pb-2", styles.storyElementToolbar)}>
          <FontAwesomeIcon icon="bars" size="lg" className="p-2" />
          <FontAwesomeIcon icon="pencil" size="lg" className="p-2" />
          <FontAwesomeIcon icon="times" size="lg" className="p-2" />
        </div>
      </div>
      <div>
        {storyElement.type === "paragraph" ? (
          <Paragraph storyElement={storyElement} />
        ) : (
          <div className={getClassName(styles.query, "flex", "center", "horizontalCenter")}>
            <FontAwesomeIcon size="10x" icon={storyElement.query ? getQueryIcon(storyElement.query) : ("?" as any)} />
          </div>
        )}
      </div>
    </div>
  );
};

const Warning: React.FC<{ query?: Models.Query; storyAccessLevel: Models.AccessLevel }> = ({
  query,
  storyAccessLevel,
}) => {
  const acl = useAcl();
  const dispatch = useDispatch();

  const allowedToEditQuery = acl.check({
    action: "manageQuery",
    context: {
      accessLevel: query?.accessLevel,
      newAccessLevel: storyAccessLevel,
      roleInOwnerAccount: acl.getRoleInAccount(query?.owner),
    },
  }).granted;
  const allowedToEditDataset = acl.check({
    action: "editDatasetMetadata",
    context: {
      accessLevel: query?.dataset?.accessLevel,
      newAccessLevel: storyAccessLevel,
      roleInOwnerAccount: acl.getRoleInAccount(query?.dataset?.owner),
    },
  }).granted;

  if (!query) {
    return (
      <Alert severity="warning" className={styles.warningElement}>
        The Query is not accessible
      </Alert>
    );
  }
  const queryLink = `/${query.owner.accountName}/-/queries/${query.name}/${query.version}`;
  if (!query.dataset) {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditQuery && (
            <Button to={queryLink} component={Link} size="small" variant="text" color="warning">
              Go to query
            </Button>
          )
        }
      >
        The dataset is not accessible
      </Alert>
    );
  }
  if (!query.service && query.serviceConfig.type !== "speedy") {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditQuery && (
            <Button to={queryLink} component={Link} size="small" variant="text" color="warning">
              Go to query
            </Button>
          )
        }
      >
        The service is not accessible
      </Alert>
    );
  }
  if (isStricterThan(query.dataset?.accessLevel, storyAccessLevel)) {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditDataset && (
            <Button
              to={`/${query.dataset.owner.accountName}/${query.dataset.name}/settings`}
              component={Link}
              size="small"
              variant="text"
              color="warning"
            >
              Go to dataset
            </Button>
          )
        }
      >
        The dataset connected to this query has a more restrictive access level than the story
      </Alert>
    );
  }
  if (isStricterThan(query.accessLevel, storyAccessLevel)) {
    return (
      <Alert
        severity="warning"
        className={styles.warningElement}
        action={
          allowedToEditQuery && (
            <Button
              variant="text"
              color="warning"
              size="small"
              onClick={() => {
                return dispatch<typeof updateQuery>(
                  query &&
                    updateQuery(query, {
                      accessLevel: storyAccessLevel,
                    }),
                );
              }}
            >
              Set query access level to {storyAccessLevel}
            </Button>
          )
        }
      >
        This query has a more restrictive access level than the story
      </Alert>
    );
  }
  return null;
};
