import { set } from "lodash-es";
import * as React from "react";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { stringifyQuery } from "#helpers/utils.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { Button, ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import InstanceForm from "../InstanceForm";
import formValuesToSparqlValues from "../InstanceForm/formValuesToSparqlValues";
import { getChangeDiff } from "../InstanceForm/getFormChanges";
import { MuiColor } from "../Process/processDefinitions";

const factory = factories.compliant;

const CopyResource: React.FC<{
  resource: string;
  color?: MuiColor;
  name: string;
  toStatus: string;
}> = ({ resource, color, name, toStatus }) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const history = useHistory();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const removePrefixes = useRemovePrefixes();

  const authorAcc = useAuthenticatedUser();

  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const historyIri = factory.namedNode(`${consoleUrl}/${datasetPath}/history/r-${uuid()}`);

  const dispatch = useDispatch();
  const search = useCurrentSearch();

  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        color={color === "default" ? undefined : color}
        elevation
        onClick={() => setOpen(true)}
        title="Copy instance"
        startIcon={<FontAwesomeIcon icon="copy" />}
        size="small"
      >
        {name}
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now, all changes will be lost."
      />
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        title="Copy instance"
        closeButton
        disableEscapeKeyDown
      >
        <div className="px-5 pb-5">
          <InstanceForm
            onDirty={setDirty}
            editingResource={resource}
            isCopy
            onSubmit={async (values, initialValues) => {
              const id = factory.namedNode(removePrefixes(values.iri.trim()));

              const propertyValues = formValuesToSparqlValues(id.id, values.properties, removePrefixes);
              const changeLog = getChangeDiff(values, initialValues!, true);

              const query = `
                prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

                insert {
                  ?createdValue a ${termToString(factory.namedNode(values.type!.id))}.

                  ?historyObject a meta:Event;
                    meta:actor ?creator;
                    meta:time ?createdAt;
                    meta:product ?createdValue;
                    meta:toStatus ?status;
                    meta:action ?takenAction;
                    meta:summary ?changeLog.
                   

                  ?subject ?predicate ?object .
                } where {
                  bind(now() as ?createdAt)
                  bind(${termToString(id)} as ?createdValue)
                  bind(${termToString(historyIri)} as ?historyObject)
                  bind(${termToString(factory.namedNode(authorUrl))} as ?creator)
                  bind(${termToString(factory.literal(changeLog))} as ?changeLog)
                  values (?status ?takenAction) {
                    ( ${toStatus !== "unknown" ? termToString(factory.literal(toStatus)) : "undef"} ${termToString(factory.literal(name))})
                  }
                  optional {
                    values (?subject ?predicate ?object) {
                      ${propertyValues.map((triple) => `(${triple})`).join("\n\t\t")}
                    }
                  }
                }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              setOpen(false);

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );

              set(search, "resource", id.id);
              history.push({
                search: stringifyQuery(search),
              });
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default CopyResource;
