import { delimitedIndent, TreeIndentContext } from "@codemirror/language";
import Debug from "debug";
import {
  ConstructQuery,
  DatasetClause,
  PropertyListNotEmpty,
  PropertyListPathNotEmpty,
  SelectClause,
  WhereClause,
} from "./sparqlParser.terms";

const debug = Debug("triply:sparql-editor:indent");

/**
 * No indent for these tokens.
 */
export function noIndent(context: TreeIndentContext) {
  debug("noIndent");
  return null;
}

/**
 * Group graph patterns need special indentation rules as this is the working node for an "incomplete" query
 * The group graph pattern is also the autocomplete token directly after a ';' without more context
 * Falls back to the delimted context from CM
 */
export function indentGroupGraphPattern(context: TreeIndentContext) {
  debug("indentGroupGraphPattern");
  if (context.simulatedBreak) {
    const cursor = context.node.toTree().cursorAt(context.pos - context.node.from, -1);
    do {
      if (cursor.node.name === ".") {
        break;
      }
      // The list break was a ; meaning this should be a
      if (
        cursor.node.name === ";" &&
        (cursor.node.parent?.type.id === PropertyListPathNotEmpty ||
          cursor.node.parent?.type.id === PropertyListNotEmpty)
      ) {
        return context.baseIndent + context.unit * 2;
      }
    } while (cursor.prev());
  }
  return delimitedIndent({ closing: "}", align: false })(context);
}

/**
 * Triple patterns template patterns need special indentation rules as this is the working node for an "incomplete" query
 * Falls back to the delimted context from CM
 */
export function indentTriplesPattern(context: TreeIndentContext) {
  debug("indentTriplesPattern");

  //for the closing bracket of the construct template
  if (context.simulatedBreak) {
    // SimulatedBreak means a linebreak is being injected
    const cursor = context.node.toTree().cursorAt(context.pos - context.node.from, -1);
    do {
      if (cursor.node.name === ".") break;
      if (cursor.node.name === ";" && cursor.node.parent?.type.id === PropertyListNotEmpty) {
        return context.baseIndent + context.unit * 2;
      }
    } while (cursor.prev());
  }
  // When in an construct query, we only want to apply this when we're using the `construct where` variant
  if (context.node.type.id === ConstructQuery) {
    if (context.node.getChild(WhereClause)) {
      return context.baseIndent;
    } else {
      // We only want to apply the delimited Indent within the brackets
      const startBracketPos = context.node.getChild("{")?.to;
      const endBracketPos = context.node.getChild("}")?.from;
      if (
        (startBracketPos !== undefined && startBracketPos >= context.pos) ||
        (endBracketPos !== undefined && endBracketPos < context.pos)
      ) {
        return context.baseIndent;
      }
    }
  }

  return delimitedIndent({ closing: "}", align: false })(context);
}

/**
 * Indents select query. Only used for adding the correct indentation between the select- and where-clause.
 */
export function indentSelectQuery(context: TreeIndentContext) {
  debug("indentSelectQuery");
  const selectClause = context.node.getChild(SelectClause);
  /**
   * Select
   *   ?s
   *   ?p|
   * where
   *
   * Not
   * Select * |
   */

  if (
    context.simulatedBreak &&
    selectClause !== null &&
    selectClause.getChild("*") === null &&
    selectClause.to == context.pos
  ) {
    return context.baseIndent + context.unit;
  }
  return context.baseIndent;
}
