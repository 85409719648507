import { Autocomplete, AutocompleteProps, ListItem, ListItemText, TextField } from "@mui/material";
import getClassName from "classnames";
import { debounce } from "lodash-es";
import { stringToTerm } from "rdf-string";
import * as React from "react";
import { Highlight } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useFetch from "../../../helpers/hooks/useFetch";
import { VariableType } from "./QueryVariables";
import * as styles from "./style.scss";

interface Props
  extends Omit<AutocompleteProps<string, false, false, true>, "options" | "renderInput" | "onChange" | "freeSolo"> {
  datasetPath: string;
  variableType: VariableType;
  datatype?: string;
  language?: string;
  error?: string;
  onChange: (value: string) => void;
}

const AutosuggestTerm = React.forwardRef<unknown, Props>(
  ({ datasetPath, variableType, datatype, language, error, onChange, ...props }, ref) => {
    const [searchString, setSearchString] = React.useState<string>(props.value || "");
    const constructUrlToApi = useConstructUrlToApi();
    const debouncedSetSearchString = React.useMemo(() => debounce(setSearchString, 250), [setSearchString]);
    const termsPath = constructUrlToApi({
      pathname: `/datasets/${datasetPath}/terms`,
      query: {
        q: `${variableType !== "NamedNode" ? '"' : ""}${searchString}`,
        dataType:
          variableType === "TypedLiteral" && datatype
            ? datatype
            : variableType === "StringLiteral"
              ? "http://www.w3.org/2001/XMLSchema#string"
              : undefined,
        languageTag: variableType === "LanguageStringLiteral" && language ? language : undefined,
        termType: variableType === "NamedNode" ? "NamedNode" : "Literal",
      },
    });
    const { data, error: fetchError } = useFetch<string[]>(termsPath, {}, [termsPath]);
    const options = Array.isArray(data) && !fetchError ? data?.map((termString) => stringToTerm(termString).value) : [];

    return (
      <Autocomplete<string, false, false, true>
        {...props}
        ref={ref}
        value={props.value || ""}
        freeSolo={true}
        options={options}
        onChange={(_e, value) => onChange(value || "")}
        renderInput={({ inputProps, ...rest }) => (
          <TextField
            {...rest}
            fullWidth
            value={props.value}
            error={!!error}
            helperText={error}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange?.(e.target.value);
              debouncedSetSearchString(e.target.value);
            }}
            inputProps={{
              ...inputProps,
              width: typeof inputProps.width === "number" ? `${inputProps.width}px` : inputProps.width,
              height: typeof inputProps.height === "number" ? `${inputProps.height}px` : inputProps.height,
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const displayValue = option.trim().split("\n")[0];
          const showEllipsis = option.trim().length > displayValue.length;

          return (
            <ListItem key={option} {...props} className={getClassName(styles.autocompleteItem, props.className)}>
              <ListItemText>
                <Highlight fullText={displayValue} highlightedText={inputValue} />
                {showEllipsis ? "…" : null}
              </ListItemText>
            </ListItem>
          );
        }}
      />
    );
  },
);

export default AutosuggestTerm;
