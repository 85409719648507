import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import * as React from "react";
import { Models } from "@triply/utils";
import useDispatch from "../../helpers/hooks/useDispatch.ts";
import { storyElementToUpdateObj, updateStory } from "../../reducers/stories.ts";
import AddItemButton from "./AddItemButton.tsx";
import StoryElement, { MockElement } from "./StoryElement.tsx";
import StoryElementDialog from "./StoryElementDialog.tsx";
import * as styles from "./StoryElement.scss";

const StoryElements: React.FC<{ story: Models.Story; editMode: boolean; className?: string }> = ({
  story,
  editMode,
  className,
}) => {
  const dispatch = useDispatch();

  const [activeId, setActiveId] = React.useState<number | string | null>(null);

  const handleStoryElementWidthChange = React.useCallback(
    (storyElementId: string, width: Models.StoryElementWidth) => {
      const content = [...story.content];
      const storyElementIdx = content.findIndex((el) => el.id === storyElementId);
      if (storyElementIdx >= 0) {
        const newEl = { ...content[storyElementIdx], width: width };
        content[storyElementIdx] = newEl;

        return dispatch<typeof updateStory>(updateStory(story, { content: content.map(storyElementToUpdateObj) }));
      } else {
        throw new Error("StoryElement is missing");
      }
    },
    [dispatch, story],
  );
  const handleStoryElementHeightChange = React.useCallback(
    (storyElementId: string, height: Models.StoryElementHeight) => {
      const content = [...story.content];
      const storyElementIdx = content.findIndex((el) => el.id === storyElementId);
      if (storyElementIdx >= 0) {
        const newEl = { ...content[storyElementIdx], height: height };
        content[storyElementIdx] = newEl;
        return dispatch<typeof updateStory>(updateStory(story, { content: content.map(storyElementToUpdateObj) }));
      } else {
        throw new Error("StoryElement is missing");
      }
    },
    [dispatch, story],
  );

  const handleStoryElementOrderChange = React.useCallback(
    (oldIndex: number, newIndex: number) => {
      if (!story || oldIndex === newIndex) return;
      const order = [...story.content];
      order.splice(newIndex, 0, order.splice(oldIndex, 1)[0]);
      return dispatch<typeof updateStory>(updateStory(story, { content: order.map(storyElementToUpdateObj) }, order));
    },
    [dispatch, story],
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  return (
    <>
      <StoryElementDialog story={story} />
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
        collisionDetection={closestCenter}
        onDragStart={(event) => {
          setActiveId(event.active.id);
        }}
        onDragCancel={(event) => {
          setActiveId(null);
        }}
        onDragEnd={(event) => {
          const { active, over } = event;
          setActiveId(null);
          if (over?.id === undefined || over?.id === null) return;
          if (active.id !== over.id) {
            return handleStoryElementOrderChange(
              story.content.findIndex((item) => item.id === active.id),
              story.content.findIndex((item) => item.id === over.id),
            );
          }
        }}
      >
        <SortableContext items={story.content.map((el) => el.id)}>
          {story.content.map((storyElement, index) => (
            <React.Fragment key={storyElement.id}>
              {editMode && <AddItemButton position={index} dragging={!!activeId} />}
              <StoryElement
                storyElement={storyElement}
                story={story}
                editMode={editMode}
                handleStoryElementWidthChange={handleStoryElementWidthChange}
                handleStoryElementHeightChange={handleStoryElementHeightChange}
              />
            </React.Fragment>
          ))}
        </SortableContext>
        {editMode && <AddItemButton dragging={!!activeId} />}
        <DragOverlay>
          {activeId && <MockElement storyElement={story.content.find((el) => el.id === activeId)!} />}
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default StoryElements;
