import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { asyncConnect } from "redux-connect";
import { RedirectRule } from "@triply/utils/Models.js";
import { urlInfoToString } from "@triply/utils-private";
import { Button, ErrorPage, FlexContainer, FontAwesomeIcon, Label, Meta } from "#components/index.ts";
import useAcl from "#helpers/hooks/useAcl.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { GlobalState } from "#reducers/index.ts";
import { getRedirects, needToFetchRedirects, putRedirects } from "#reducers/redirects.ts";
import RedirectForm from "./RedirectForm.tsx";
import * as styles from "./style.scss";

const AdminRedirects: React.FC<{}> = ({}) => {
  const [editingRuleId, setEditingRuleId] = React.useState<string | null>(null);
  const redirects = useSelector((state: GlobalState) => state.redirects.list);
  const acl = useAcl();
  const location = useLocation();
  const dispatch = useDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const editingRule = redirects.find((item) => item.id === editingRuleId);
  const onDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over?.id === undefined || over?.id === null || redirects === undefined) return;
      if (active.id !== over.id) {
        const newValue = [...redirects];
        const movedItem = newValue.splice(
          redirects.findIndex((item) => item.id === active.id),
          1,
        );
        newValue.splice(
          redirects.findIndex((item) => item.id === over.id),
          0,
          movedItem[0],
        );
        dispatch<typeof putRedirects>(putRedirects(newValue)).catch(() => {});
      }
    },
    [dispatch, redirects],
  );
  const removeRule = React.useCallback(
    (id: string) => {
      const newValue = [...redirects];
      newValue.splice(
        redirects.findIndex((item) => item.id === id),
        1,
      );
      dispatch<typeof putRedirects>(putRedirects(newValue)).catch(() => {});
    },
    [dispatch, redirects],
  );
  const editRule = React.useCallback((id: string) => {
    setEditingRuleId(id);
  }, []);

  if (!acl.check({ action: "manageRedirects" }).granted) return <ErrorPage statusCode={401} />;
  return (
    <div>
      <Meta currentPath={location.pathname} title="Redirects - Admin settings" />

      <FlexContainer className="mt-5">
        <div className={"whiteSink"}>
          <h4>Redirects</h4>
          <Introduction />
          <div>
            <Button color="secondary" title="Add redirection rule" onClick={() => setEditingRuleId("new")}>
              Add rule
            </Button>
          </div>
          <DndContext
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            sensors={sensors}
            onDragEnd={onDragEnd}
          >
            <List dense>
              <SortableContext items={redirects.map((item) => item.id!)}>
                {redirects.map((rule) => (
                  <SortableItem rule={rule} onDelete={removeRule} onEdit={editRule} key={rule.id} />
                ))}
              </SortableContext>
            </List>
          </DndContext>
        </div>
        {editingRuleId && (
          <RedirectForm
            onCancel={() => setEditingRuleId(null)}
            onSubmit={(newRule) => {
              const newRedirects = [...redirects];
              if (editingRuleId === "new") {
                newRedirects.push(newRule);
              } else {
                const currentIndex = redirects.findIndex((rule) => rule.id === editingRuleId);
                newRedirects[currentIndex] = newRule;
              }
              return dispatch<typeof putRedirects>(putRedirects(newRedirects)).then(() => setEditingRuleId(null));
            }}
            initialData={editingRule}
          />
        )}
      </FlexContainer>
    </div>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: ({ store: { dispatch, getState } }) => {
      if (needToFetchRedirects(getState())) {
        return dispatch<any>(getRedirects());
      }
    },
  },
])(AdminRedirects);

const SortableItem: React.FC<{ rule: RedirectRule; onEdit: (id: string) => void; onDelete: (id: string) => void }> = ({
  rule,
  onDelete,
  onEdit,
}) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: rule.id!,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (typeof rule.toDataset === "string") return <>?</>;
  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      secondaryAction={[
        <IconButton
          key="Edit"
          title="Edit"
          aria-label="Edit"
          size="small"
          onClick={() => {
            onEdit(rule.id!);
          }}
        >
          <FontAwesomeIcon icon="pencil" />
        </IconButton>,
        <IconButton
          key="Remove"
          title="Remove"
          aria-label="Remove"
          size="small"
          onClick={() => {
            onDelete(rule.id!);
          }}
        >
          <FontAwesomeIcon icon="times" />
        </IconButton>,
      ]}
    >
      <ListItemIcon>
        <IconButton
          ref={setActivatorNodeRef}
          {...listeners}
          {...attributes}
          className={getClassName(styles.dragHandle)}
          size="small"
        >
          <FontAwesomeIcon className={getClassName()} icon="bars" />
        </IconButton>
      </ListItemIcon>
      <ListItemIcon>
        {rule.matchingMethod === "prefix" && <Label primary>Prefix</Label>}
        {rule.matchingMethod === "regexp" && <Label error>Regex</Label>}
      </ListItemIcon>
      <ListItemText>
        <span className={styles.match}>{rule.match}</span>
        <Link
          to={"/" + rule.toDataset.owner.accountName + "/" + rule.toDataset.name}
          className={getClassName("ml-3", styles.datasetLink)}
        >
          {rule.toDataset.owner.accountName + " / " + rule.toDataset.name}
        </Link>
      </ListItemText>
    </ListItem>
  );
};

const Introduction: React.FC = () => {
  const baseIri = useSelector((state: GlobalState) => urlInfoToString(state.config.staticConfig?.apiUrl));
  return (
    <>
      <p>
        Redirects enable easy dereferencing of resources. For example, you can dereference a resource{" "}
        <ExampleLink>https://example.org/resource/Amsterdam</ExampleLink> into dataset{" "}
        <ExampleLink>{`${baseIri}/MyAccount/MyDataset`}</ExampleLink> by following these steps:
      </p>
      <ol>
        <li>
          Update the webserver of <ExampleLink>https://example.org</ExampleLink> to redirect all subpaths of{" "}
          <code>/resource</code> to <ExampleLink>{`${baseIri}/redirect/$requestUri`}</ExampleLink>.
        </li>
        <li>
          Add rules below to map incoming IRIs to datasets. For example, the prefix{" "}
          <ExampleLink>https://example.org/resource/City/</ExampleLink> could be mapped to dataset{" "}
          <ExampleLink>{`${baseIri}/myAccount/myCities`}</ExampleLink>, and the prefix{" "}
          <ExampleLink>https://example.org/resource/Country/</ExampleLink> could be mapped to dataset{" "}
          <ExampleLink>{`${baseIri}/myAccount/myCountries`}</ExampleLink>. Mapping rules are evaluated from top (highest
          priority) to bottom (lowest priority).
        </li>
      </ol>
      <p>Two types of mapping rules are supported:</p>
      <ul>
        <li className="flex">
          <div className={getClassName("flex", styles ? styles.redirectLabel : undefined)}>
            <Label primary>Prefix</Label>
          </div>{" "}
          Prefix rules trigger when the start of a resource matches the specified string.
        </li>
        <li className="flex">
          <div className={getClassName("flex", styles ? styles.redirectLabel : undefined)}>
            <Label error>Regex</Label>
          </div>{" "}
          Regular Expression rules trigger when a resource matches a Regular Expression.
        </li>
      </ul>
    </>
  );
};

const ExampleLink: React.FC<{ children: string }> = ({ children: link }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={link} suppressHydrationWarning>
      {link}
    </a>
  );
};
