import { NoSsr, Paper } from "@mui/material";
import Alert from "@mui/material/Alert";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { ResizableBox, ResizeCallbackData } from "react-resizable";
import { asyncConnect } from "redux-connect";
import useResizeObserver from "use-resize-observer";
import { ErrorPage } from "#components/index.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useLocalStorage from "#helpers/hooks/useLocalStorage.tsx";
import { parseSearchString } from "#helpers/utils.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { getCurrentDataset, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { setLastDataEditorResource } from "#reducers/datasets.ts";
import { GlobalState } from "#reducers/index.ts";
import {
  classIsLoadedFore,
  descriptionIsLoadedFor,
  getClassDescription,
  getDescription,
} from "#reducers/resourceEditorDescriptions.ts";
import InstanceView from "../InstanceView/index.tsx";
import ExpandedContextProvider from "./tree/ExpandedContext.tsx";
import SkosTreeContextProvider from "./tree/SkosTreeContext.tsx";
import Meta from "./Meta.tsx";
import SkosTree from "./SkosTree.tsx";
import { CachedSparqlContext } from "./useCachedSparql.tsx";
import * as styles from "./style.scss";

const DEFAULT_VIEW_WIDTH = 400;

const Container: React.FC<{}> = ({}) => {
  const resource = useCurrentResource();
  const ds = useCurrentDataset();
  const account = useCurrentAccount();
  const resourceDescription = useSelector((state: GlobalState) =>
    !!ds && !!resource ? state.resourceEditorDescriptions[ds!.id]?.resources?.[resource] : undefined,
  );

  const [initialWidth, setInitialWidth] = useLocalStorage(`skosWidth-${ds!.id}`, DEFAULT_VIEW_WIDTH + "");

  const { ref: resizeObserverRef, width: viewWidth = +initialWidth || DEFAULT_VIEW_WIDTH } = useResizeObserver();
  const [width, setWidth] = React.useState(+initialWidth || DEFAULT_VIEW_WIDTH);
  const setSize = React.useCallback(
    (_e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
      setWidth(data.size.width);
      setInitialWidth(data.size.width + "");
    },
    [setInitialWidth],
  );
  const maxWidth = viewWidth - 100;
  if (!account || !ds) {
    return <ErrorPage statusCode={404} />;
  }

  return (
    <div className={getClassName("p-3", styles.skos)} ref={resizeObserverRef}>
      <Meta currentAccount={account} currentDs={ds} resourceDescription={resourceDescription} />
      <ResizableBox
        width={Math.min(width, maxWidth)}
        axis="x"
        minConstraints={[400, 0]}
        maxConstraints={[maxWidth, Infinity]}
        resizeHandles={["e"]}
        onResize={setSize}
        className={styles.resizableBox}
        handle={
          <div className={styles.resizeHandle}>
            <div />
          </div>
        }
      >
        <CachedSparqlContext>
          <SkosTreeContextProvider>
            <ExpandedContextProvider>
              <SkosTree className={styles.skosTree} />
            </ExpandedContextProvider>
          </SkosTreeContextProvider>
        </CachedSparqlContext>
      </ResizableBox>
      <InstanceView
        resource={resource}
        dsId={ds.id}
        className={styles.instancePaper}
        innerClassName={styles.instanceScroll}
      />
    </div>
  );
};

export default asyncConnect<GlobalState>([
  {
    promise: async ({ location, store: { dispatch, getState } }) => {
      try {
        const state: GlobalState = getState();
        const query = parseSearchString(location.search);
        const currentDs = getCurrentDataset(state);
        if (!state.config.staticConfig) return;

        if (currentDs) {
          let resource: string | undefined;
          if (typeof query.resource === "string" && query.resource.length) {
            /**
             * We've got request a resource via url arguments
             */
            resource = query.resource;
          }
          if (resource && state.datasets[currentDs.id].lastDataEditorResource !== resource) {
            /**
             * Mark this resource as the last data editor resource visited
             */
            dispatch(setLastDataEditorResource(currentDs.id, resource));
          }

          if (
            resource &&
            !descriptionIsLoadedFor({
              state: state.resourceEditorDescriptions,
              dataset: currentDs,
              resource: resource,
            })
          ) {
            /**
             * Fetch resource description when needed
             */
            await dispatch<any>(getDescription({ dataset: currentDs, resource: resource }));
          }
          const res = !!resource && getState().resourceEditorDescriptions[currentDs.id]?.resources?.[resource];
          if (!res) return;
          const classIri = res.type;
          const promises: Promise<any>[] = [];
          const classesOfNestedNodes = new Set<string>();
          if (
            classIri &&
            !classIsLoadedFore({
              state: state.resourceEditorDescriptions,
              dataset: currentDs,
              classIri: classIri,
            })
          ) {
            classesOfNestedNodes.add(classIri);
          }
          for (const propertyArray of Object.values(res.properties || {})) {
            for (const property of propertyArray) {
              if (
                property.nodeKind === "NestedNode" &&
                property.type &&
                !classIsLoadedFore({
                  state: state.resourceEditorDescriptions,
                  dataset: currentDs,
                  classIri: property.type,
                })
              ) {
                classesOfNestedNodes.add(property.type);
              }
            }
          }
          classesOfNestedNodes.forEach((classIri) =>
            promises.push(dispatch<any>(getClassDescription({ dataset: currentDs, classIri: classIri }))),
          );
          await Promise.all(promises);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
])(Container);
