import * as React from "react";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import fetch from "#helpers/fetch.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { ConfirmationDialog, Dialog } from "../../../components";
import useConstructConsoleUrl from "../../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { useCurrentDataset } from "../../../reducers/datasetManagement";
import InstanceForm from "../InstanceForm";
import formValuesToSparqlValues from "../InstanceForm/formValuesToSparqlValues";
import { getChangeDiff } from "../InstanceForm/getFormChanges";

const factory = factories.compliant;

interface Props {
  toStatus: string;
  name: string;
  instanceOf: string;
  onDone: (iri?: string, label?: string) => void;
}

const CreateEmbeddedResource: React.FC<Props> = ({ toStatus, name, instanceOf, onDone }) => {
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const authorAcc = useAuthenticatedUser();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/update`,
    fromBrowser: true,
  });
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      onDone();
    }
  };
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const historyIri = factory.namedNode(`${consoleUrl}/${datasetPath}/history/r-${uuid()}`);

  return (
    <>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          onDone();
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now all changes will be lost"
        stopPropagation
      />
      <Dialog
        open
        onClose={onClose}
        maxWidth="md"
        fullWidth
        title="Create a new instance"
        closeButton
        disableEscapeKeyDown
      >
        <div className="px-5 pb-5">
          <InstanceForm
            onDirty={setDirty}
            instanceOf={instanceOf}
            onSubmit={async (values) => {
              const id = factory.namedNode(removePrefixes(values.iri.trim()));
              const propertyValues = formValuesToSparqlValues(id.id, values.properties, removePrefixes);

              const query = `
                prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

                insert {
                  ?createdValue a ${termToString(factory.namedNode(values.type!.id))}.

                  ?historyObject a meta:Event;
                    meta:actor ?creator;
                    meta:time ?createdAt;
                    meta:product ?createdValue;
                    meta:summary ?changeLog;
                    meta:toStatus ?status;
                    meta:action ?takenAction.

                  ?subject ?predicate ?object .
                } where {
                  bind(now() as ?createdAt)
                  bind(${termToString(id)} as ?createdValue)
                  bind(${termToString(factory.literal(getChangeDiff(values)))} as ?changeLog)
                  bind(${termToString(historyIri)} as ?historyObject)
                  bind(${termToString(factory.namedNode(authorUrl))} as ?creator)
                  values (?status ?takenAction) {
                    ( ${toStatus !== "unknown" ? termToString(factory.literal(toStatus)) : "undef"} ${termToString(factory.literal(name))})
                  }
                  optional {
                    values (?subject ?predicate ?object) {
                      ${propertyValues.map((triple) => `(${triple})`).join("\n\t\t")}
                    }
                  }
                }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });
              let label: string | undefined;
              // rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm|skosxl:literalForm
              if ("http://www w3 org/2000/01/rdf-schema#label" in values.properties) {
                label = values.properties["http://www w3 org/2000/01/rdf-schema#label"][0]?.value;
              }
              if (!label && "http://www w3 org/2004/02/skos/core#prefLabel" in values.properties) {
                label = values.properties["http://www w3 org/2004/02/skos/core#prefLabel"][0]?.value;
              }
              if (!label && "http://www w3 org/2008/05/skos-xl#prefLabel" in values.properties) {
                const property = values.properties["http://www w3 org/2008/05/skos-xl#prefLabel"][0];
                if (property?.nodeKind === "NestedNode") {
                  label = property.properties["http://www w3 org/2008/05/skos-xl#literalForm"][0]?.value;
                }
              }
              if (!label && "http://www w3 org/2008/05/skos-xl#literalForm" in values.properties) {
                label = values.properties["http://www w3 org/2008/05/skos-xl#literalForm"][0]?.value;
              }
              onDone(id.id, label);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default CreateEmbeddedResource;
