import { IconButton } from "@mui/material";
import bytes from "bytes";
import getClassName from "classnames";
import { last } from "lodash-es";
import * as React from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Models } from "@triply/utils";
import { Button, FontAwesomeButton, FontAwesomeIcon, HumanizedDate, RemoveBtn } from "#components/index.ts";
import { Account } from "#reducers/accountCollection.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import * as styles from "./AssetListItem.scss";

export namespace AssetListItem {
  export interface Props {
    asset: Models.Asset;
    currentAccount: Account;
    currentDs: Dataset;
    removeAsset: (assetIdentifier: string) => void;
    writeAccess: boolean;
    addVersion?: (file?: File[], hash?: string) => void;
    removeVersion?: (assetIdentifier: string, versionId: string) => void;
    className?: string;
    allowedAssetExtensions: string[] | undefined;
  }
  export interface State {
    showVersions: boolean;
  }
}

class AssetListItem extends React.PureComponent<AssetListItem.Props, AssetListItem.State> {
  constructor(props: AssetListItem.Props) {
    super(props);
    this.state = {
      showVersions: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: AssetListItem.Props) {
    if (nextProps.asset && nextProps.asset.versions.length < 2) this.setState({ showVersions: false });
  }

  toggleVersions = () => {
    this.setState({ showVersions: !this.state.showVersions });
  };

  addVersion = (files: File[]) => {
    if (files && files.length && this.props.addVersion) {
      this.props.addVersion(files, this.props.asset.identifier);
    }
  };
  doNothing = () => {};
  removeAsset = () => this.props.removeAsset(this.props.asset.identifier);
  render() {
    const { className, allowedAssetExtensions } = this.props;
    const { currentAccount, currentDs, asset, removeAsset, addVersion, removeVersion, writeAccess } = this.props;
    const { showVersions } = this.state;
    const latest = last(asset.versions) as Models.AssetVersion;
    let accept;
    if (allowedAssetExtensions?.length) {
      accept = {
        // Dropzone requires a mapping between mime-type and extension.
        // We use a fake mimetype: For our usecase, the extension seems to suffice: we can only select files with these extensions
        "application/x-triply": allowedAssetExtensions,
      };
    }
    return (
      <Dropzone
        onDrop={this.addVersion}
        noKeyboard
        noClick
        accept={accept}
        // See https://issues.triply.cc/issues/7219
        useFsAccessApi={false}
      >
        {({ getRootProps, getInputProps, isDragActive, open }) => (
          <div
            {...getRootProps({
              className: getClassName(className, styles.box, { [styles.dragActive]: isDragActive }),
            })}
          >
            <input {...getInputProps()} />
            <div className={styles.main}>
              <div className={styles.wrap}>
                <div className={styles.colDescription}>
                  <a
                    target="_blank"
                    download
                    href={`/${currentAccount.accountName}/${currentDs.name}/assets/${asset.identifier}`}
                  >
                    <h4>{asset.assetName}</h4>
                  </a>
                  <div className={styles.description}>
                    {bytes(latest.fileSize)}, uploaded <HumanizedDate date={latest.uploadedAt} />
                    {latest.uploadedBy && (
                      <span>
                        {" "}
                        by{" "}
                        <Link to={`/${latest.uploadedBy.accountName}`} target="_blank">
                          {" "}
                          {latest.uploadedBy.name || latest.uploadedBy.accountName}
                        </Link>
                      </span>
                    )}
                    {asset.versions.length > 1 && (
                      <FontAwesomeButton
                        iconOnStart
                        aria-label={showVersions ? "Hide history" : "Show history"}
                        icon={["fas", showVersions ? "caret-down" : "caret-right"]}
                        onClick={this.toggleVersions}
                        aria-expanded={showVersions}
                        aria-controls={`${asset.identifier}-versions`}
                      >
                        {showVersions ? "Hide history" : "Show history"}
                      </FontAwesomeButton>
                    )}
                  </div>
                </div>
                <div className={getClassName(styles.colIcons, styles.buttons)}>
                  {writeAccess && addVersion && (
                    <Button
                      size="small"
                      variant="outlined"
                      className={styles.button}
                      onClick={open}
                      startIcon={<FontAwesomeIcon icon="upload" />}
                    >
                      Upload new version
                    </Button>
                  )}
                  <Button
                    href={`/${currentAccount.accountName}/${currentDs.name}/assets/${asset.identifier}`}
                    LinkComponent={React.forwardRef(({ children, ...props }, ref) => (
                      <a {...props} ref={ref} download target="_blank">
                        {children}
                      </a>
                    ))}
                    size="small"
                    variant="outlined"
                    className={styles.button}
                    startIcon={<FontAwesomeIcon icon="download" />}
                  >
                    Download
                  </Button>
                </div>
              </div>

              <div className={styles.colIcons}>
                {writeAccess && !!removeAsset && <RemoveBtn title="Delete asset" onClick={this.removeAsset} />}
              </div>
            </div>

            {showVersions && (
              <table className={styles.versions} id={`${asset.identifier}-versions`}>
                {[...asset.versions].reverse().map((v, k) => (
                  <tr key={k} className={getClassName(styles.version, "py-2")}>
                    <td>
                      <HumanizedDate showAbsoluteTime date={v.uploadedAt} hideTitle />
                    </td>
                    <td>{bytes(v.fileSize)}</td>
                    <td>
                      {v.uploadedBy && (
                        <Link to={`/${v.uploadedBy.accountName}`}>{v.uploadedBy.name || v.uploadedBy.accountName}</Link>
                      )}
                    </td>
                    <td className={"flex horizontalEnd"}>
                      <IconButton
                        size="small"
                        target="_blank"
                        href={`/${currentAccount.accountName}/${currentDs.name}/assets/${asset.identifier}/${v.id}`}
                        className="px-2 noLinkDecoration"
                        title="Download version"
                        aria-label="Download version"
                      >
                        <FontAwesomeIcon icon="download" className={styles.downloadIcon} />
                      </IconButton>
                      {writeAccess && removeVersion && (
                        <IconButton
                          title="Delete version"
                          size="small"
                          aria-label="Delete version"
                          onClick={() => removeVersion(asset.identifier, v.id)}
                        >
                          <FontAwesomeIcon icon="times" className={styles.downloadIcon} />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            )}
          </div>
        )}
      </Dropzone>
    );
  }
}
export default AssetListItem;
