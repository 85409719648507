import { fromPairs } from "lodash-es";
import * as React from "react";
import { Converter } from "sparqljson-to-tree";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize.js";
import { ClassInfo } from "./Types.ts";
import useSparql from "./useSparql";

const factory = factories.compliant;

const converter = new Converter({ materializeRdfJsTerms: true });

const getClassesQuery = (instanceOf?: string) => `
# classes

prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix dash: <http://datashapes.org/dash#>
prefix triply: <https://triplydb.com/Triply/function/>
prefix form-acl: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

select
  ?id
  (sample(?stem_t) as ?stem)
  (triply:firstLabel(?id) as ?label)
  (sample(?description_t) as ?description)
{
  ${instanceOf ? `bind(${termToString(factory.namedNode(instanceOf))} as ?class)` : ""}
  ?nodeShape sh:targetClass ?class .
  ?id rdfs:subClassOf* ?class .

  filter not exists {
    ?id dash:abstract true .
  }

  optional {
    ?nodeShape form-acl:readOnly ?readOnly .
    filter(?readOnly = true)
  }

  optional {
    ?nodeShape sh:targetClass ?id .
    ?nodeShape dash:stem ?stem_t .
  }

  optional {
    ?id rdfs:comment|skos:definition ?description_t
  }
  optional {
    ?class ^sh:targetClass/sh:order ?order
  }
}
group by ?id
having(coalesce(sample(?readOnly), false) != true)
order by (coalesce(sample(?order),1000000))
`;

const useClasses = (instanceOf?: string) => {
  const sparql = useSparql();
  const [classes, setClasses] = React.useState<ClassInfo[]>();

  React.useEffect(() => {
    sparql(getClassesQuery(instanceOf))
      .then((results) => {
        const json = converter.sparqlJsonResultsToTree(results, {
          singularizeVariables: fromPairs(results.head!.vars.map((v) => [v, true])),
        });
        setClasses(json);
      })
      .catch(() => {});
  }, [sparql, instanceOf]);

  return classes;
};

export default useClasses;
