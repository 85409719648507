import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Autocomplete,
  debounce,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import getClassName from "classnames";
import { isArray } from "lodash-es";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { Models } from "@triply/utils";
import { DatasetLicenseId, LICENSES } from "@triply/utils/Constants.js";
import { AccessLevel } from "@triply/utils/Models.js";
import {
  applyRuleConfig,
  datasetDescriptionValidations,
  getDatasetNameValidations,
  getDisplayNameValidations,
  iriValidations,
  required,
  toStringValidator,
} from "@triply/utils-private/validation.js";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import {
  AccessLevels,
  Alert,
  FontAwesomeIcon,
  FormField,
  Highlight,
  Label,
  MarkdownEditField,
  MarkdownEditFieldRedux,
  MuiAutoSuggestMulti,
  NameAndUrl,
} from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useFetch from "#helpers/hooks/useFetch.ts";
import { memoize } from "#helpers/utils.ts";
import { Dataset } from "#reducers/datasetManagement.ts";
import * as styles from "./style.scss";

const descriptionValidator = toStringValidator(datasetDescriptionValidations);
const datasetNameValidator = toStringValidator(getDatasetNameValidations());
const iriValidator = toStringValidator(iriValidations);
const datasetDisplayNameValidator = toStringValidator([
  ...getDisplayNameValidations({ messageSubject: "A dataset name" }),
  applyRuleConfig(required, {
    formatMessage: () => `A dataset name is required.`,
  }),
]);

namespace DatasetProfile {
  export interface FormData {
    name: string;
    displayName: string;
    description: string;
    accessLevel: AccessLevel;
    license: DatasetLicenseId;
    topics: Models.Topic[];
    exampleResources: string[];
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    // onSubmit:Function,//passed from parent property. Use the `handleSubmit` as actual submit handler for the form
    currentDs: Dataset;
    termPath: string;
    topicPath: string;
  }

  export interface LicenseFieldProps {
    label: string;
    children: any[];
  }
  export interface State {}
}

class _DatasetProfile extends React.PureComponent<
  DatasetProfile.Props & ReduxForm.InjectedFormProps<DatasetProfile.FormData>,
  DatasetProfile.State
> {
  constructor(props: DatasetProfile.Props & ReduxForm.InjectedFormProps<DatasetProfile.FormData>) {
    super(props);
  }
  f = <T1 extends {}>(arg1: T1) => {
    return { arg1 };
  };
  search = <I extends {}>(url: string) => {
    return this.fetch<I>(url).then((results) => {
      if (results && isArray(results)) {
        return results;
      }
      return [];
    });
  };

  @memoize({ primitive: true, promise: true })
  fetch<I>(url: string): Promise<I[]> {
    return fetch(url, { credentials: "same-origin" })
      .then((response) => {
        if (response.status === 200) return response.json();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  licenseChange = (value: DatasetLicenseId) => {
    this.setState({
      licenseLink: LICENSES[value]?.url,
    });
  };

  searchTopic = (q: string) => this.search<Models.Topic>(`${this.props.topicPath}?q=${encodeURIComponent(q)}`);

  renderLicenseField = ({ input, children }: ReduxForm.WrappedFieldProps & DatasetProfile.LicenseFieldProps) => {
    const licenseLink = LICENSES[input.value as DatasetLicenseId]?.url;
    return (
      <div className="flex center">
        <div className={styles.licenseFormEl}>
          <Select
            {...input}
            displayEmpty
            inputProps={{
              id: "license",
            }}
          >
            {children}
          </Select>
          {licenseLink && (
            <div className={styles.licenseLink}>
              <a href={licenseLink} rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon="link" />
              </a>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderTopicField = ({ input }: ReduxForm.WrappedFieldProps) => {
    return (
      <MuiAutoSuggestMulti<Models.Topic>
        className="pb-3"
        placeholder="Type to search"
        label="Add a topic"
        {...input}
        getDisplayValueFromSuggestion={(suggestion) => suggestion.label}
        getKeyFromFromSuggestion={(suggestion) => suggestion.id}
        loadSuggestions={this.searchTopic}
        TextFieldProps={{ fullWidth: true }}
        renderSuggestion={(topic, { query, isHighlighted }) => {
          return (
            <MenuItem selected={isHighlighted} component="div">
              <Highlight fullText={topic.label} highlightedText={query} />
            </MenuItem>
          );
        }}
      />
    );
  };
  render() {
    const { handleSubmit, submitting, submitSucceeded, error, pristine, valid, change, initialValues, currentDs } =
      this.props;
    return (
      <div>
        <form method="POST" onSubmit={handleSubmit}>
          <NameAndUrl
            changeFormValues={change}
            initialSlug={initialValues.name}
            formIsPristine={pristine}
            autoFocus={false}
            nameFieldName="displayName"
            nameFieldLabel="Dataset name"
            slugFieldName="name"
            slugFieldLabel="URL"
            className="mt-5 mb-7"
            urlPrefixPath={`/${currentDs.owner.accountName}/`}
          />

          <FormField label="Dataset description" className="mb-7">
            <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
              name="description"
              props={{
                type: "text",
                fullWidth: true,
                multiline: true,
                rows: 5,
              }}
              component={MarkdownEditFieldRedux}
            />
          </FormField>

          <FormField label="Access level" className="mb-7">
            <AccessLevels
              name="accessLevel"
              type="dataset"
              accountType={this.props.currentDs.owner.type}
              changeAccessLevel={(value) => this.props.change?.("accessLevel", value)}
            />
          </FormField>

          <FormField label="Topics" className="mb-7">
            <ReduxForm.Field name="topics" component={this.renderTopicField} />
          </FormField>

          <FormField label="Example resources" className="mb-7">
            <ReduxForm.Field name="exampleResources" termPath={this.props.termPath} component={ExampleItemField} />
          </FormField>

          <FormField label="License" className="mb-7">
            <ReduxForm.Field name="license" component={this.renderLicenseField as any}>
              <MenuItem value="">None</MenuItem>

              {Object.entries(LICENSES).map(([licenseId, license]) => {
                return (
                  <MenuItem key={licenseId} value={licenseId} disabled={license.deprecated}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {license.gui_name}
                      {!!LICENSES[licenseId as DatasetLicenseId]?.deprecated && (
                        <div title="This license is deprecated or no longer applicable." className={styles.licenseLink}>
                          <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} fixedWidth className="ml-1" />
                        </div>
                      )}
                    </span>
                  </MenuItem>
                );
              })}
            </ReduxForm.Field>
          </FormField>

          <Alert className="mt-5" transparent message={error} />

          <div className="form-group mt-3">
            <LoadingButton
              type="submit"
              color="secondary"
              disabled={pristine || !valid}
              onClick={handleSubmit}
              loading={submitting}
            >
              Save
            </LoadingButton>
          </div>
        </form>
        {submitSucceeded && pristine && (
          <div className="mt-3">
            <Label success message="You have successfully updated your dataset." />
          </div>
        )}
      </div>
    );
  }
}

const DatasetProfile = ReduxForm.reduxForm<DatasetProfile.FormData, DatasetProfile.Props>({
  form: "dsProfileForm",
  validate: memoizee(
    (formData: DatasetProfile.FormData) => {
      if (!formData) {
        return {};
      }
      let exampleResourceCheck = undefined;
      if (formData.exampleResources) {
        for (const resource of formData.exampleResources) {
          const check = iriValidator(resource);
          if (check) {
            exampleResourceCheck = check;
            break;
          }
        }
      }
      return {
        name: datasetNameValidator(formData.name),
        displayName: datasetDisplayNameValidator(formData.displayName),
        description: descriptionValidator(formData.description),
        exampleResources: exampleResourceCheck,
      };
    },
    { max: 10 },
  ),
  warn: memoizee(
    (formData: DatasetProfile.FormData, props: DatasetProfile.Props) => {
      return {
        name:
          (formData.name &&
            props.initialValues?.name &&
            formData.name.toLowerCase() !== props.initialValues.name.toLowerCase() &&
            "Changing the dataset URL will break existing links to the web pages and API addresses of the dataset.") ||
          undefined,
      };
    },
    { max: 10 },
  ),
})(_DatasetProfile as any);

export default DatasetProfile;

const ExampleItemField: React.FC<{ termPath: string } & ReduxForm.WrappedFieldProps> = ({
  input: { value, onChange, ...input },
  meta,
  termPath,
}) => {
  value = (value || []) as string[];
  const [inputText, setInputText] = React.useState("");
  const [search, _setSearchFor] = React.useState("");
  const [optionsFor, setOptionsFor] = React.useState("");

  const setSearchFor = React.useMemo(
    () =>
      debounce((value: string) => {
        _setSearchFor(value);
      }),
    [],
  );

  const { data: options = [] } = useFetch<string[]>(
    `${termPath}?pos=subject&q=${encodeURIComponent(search)}`,
    {
      interceptors: {
        response: async ({ response }) => {
          const originalSearch = new URL(response.url).searchParams.get("q");
          if (originalSearch) setOptionsFor(originalSearch);
          return response;
        },
      },
    },
    [search],
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const onRemove = React.useCallback(
    (toRemove: string) => {
      onChange(value.filter((resource: string) => resource !== toRemove));
    },
    [onChange, value],
  );
  return (
    <div>
      <DndContext
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={sensors}
        onDragEnd={(event) => {
          const { active, over } = event;
          if (over?.id === undefined || over?.id === null) return;
          if (active.id !== over.id) {
            const newValue = [...value];
            const movedItem = newValue.splice(
              value.findIndex((item: string) => item === active.id),
              1,
            )[0];
            newValue.splice(
              value.findIndex((item: string) => item === over.id),
              0,
              movedItem,
            );
            return onChange(newValue);
          }
        }}
      >
        <List dense disablePadding>
          <SortableContext items={value}>
            {value.map((suggestion: string) => (
              <SortableElement key={suggestion} suggestion={suggestion} onRemove={onRemove} />
            ))}
          </SortableContext>
        </List>
      </DndContext>
      <Autocomplete
        value={null} // No need  to track the value
        freeSolo
        inputValue={inputText}
        options={options}
        onChange={(_event, newValue) => {
          if (newValue) onChange([...value, newValue]);
          setInputText("");
          setSearchFor("");
        }}
        onInputChange={(_event, newInputValue, reason) => {
          if (reason !== "reset") {
            setInputText(newInputValue);
            setSearchFor(newInputValue);
          } else {
            setInputText("");
            setSearchFor("");
          }
        }}
        renderOption={(props, value) => {
          return (
            <ListItem dense {...props}>
              <ListItemText primary={<Highlight fullText={value} highlightedText={optionsFor} />} />
            </ListItem>
          );
        }}
        filterOptions={(x) => x.filter((x) => !value.includes(x))}
        renderInput={({ inputProps, ...rest }) => (
          <TextField
            {...rest}
            inputProps={{
              ...input,
              ...inputProps,
              width: inputProps?.width + "",
              height: inputProps?.width + "",
            }}
            label="Add an example resource"
            placeholder="Type to search"
            error={meta.dirty && !!meta.error}
            helperText={meta.dirty && meta.error}
          />
        )}
      />
    </div>
  );
};

const SortableElement: React.FC<{ suggestion: string; onRemove: (value: string) => void }> = ({
  suggestion,
  onRemove,
}) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: suggestion!,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      dense
      disableGutters
      ref={setNodeRef}
      className={getClassName({ [styles.dragging]: isDragging })}
      style={style}
      secondaryAction={
        onRemove && (
          <IconButton
            size="small"
            onClick={(e) => {
              onRemove(suggestion);
            }}
            aria-label="Remove item"
            title="Remove item"
          >
            <FontAwesomeIcon icon="times" />
          </IconButton>
        )
      }
    >
      <IconButton
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
        size="small"
        aria-label="Move element"
        title="Move element "
      >
        <FontAwesomeIcon icon="bars" />
      </IconButton>
      <ListItemText primary={suggestion} />
    </ListItem>
  );
};
