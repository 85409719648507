import * as React from "react";
import { useSelector } from "react-redux";
import { mergePrefixArray } from "@triply/utils/prefixUtils.js";
import { GlobalState } from "#reducers/index.ts";

export function useDatasetPrefixes() {
  const globalPrefixes = useSelector((state: GlobalState) => state.config.clientConfig?.prefixes);
  const localPrefixes = useSelector((state: GlobalState) =>
    state.datasetManagement.current ? state.datasetCollection[state.datasetManagement.current].prefixes : undefined,
  );
  const prefixes = React.useMemo(
    () => mergePrefixArray(localPrefixes || [], globalPrefixes || []),
    [globalPrefixes, localPrefixes],
  );
  return prefixes;
}
