/**
 * A utility for getting settings from the values file. Useful to avoid repeating the same
 * logic in other place for getting certain settings that require some more (possibly
 * intricate) logic
 */
import fs from "fs";
import { DeepPick } from "ts-essentials";
import { TriplyDBChartSchema } from "#values.js";

export const valuesPath = process.env.VALUES_FILE || "/home/triply/values/values.json";
export function readValuesFromFile(): TriplyDBChartSchema {
  return JSON.parse(fs.readFileSync(valuesPath, "utf-8"));
}
export function valuesWatcher(opts: { onReload: (values: TriplyDBChartSchema) => void }) {
  let latestValuesFileMTime = 0;
  const watcher = fs.watch(valuesPath, () => {
    /**
     * We noticed that on certain environments (not locally!) the watch event triggers
     * every ~30 seconds.
     * This seems to be caused by the use of different volume.
     * To avoid superfluous log statements, we compare the modified (not the changed) date, and accept that
     * we read this file every ~30 seconds.
     */
    const lastModified = fs.statSync(valuesPath).mtimeMs;
    if (lastModified > latestValuesFileMTime) {
      latestValuesFileMTime = lastModified;
      // Using sync, as the watch callback doesn't support promises. It also doesn't happen often
      opts.onReload(readValuesFromFile());
    }
  });
  return () => watcher.close();
}

export const ServiceTypeToHelmMapping = {
  elasticSearch: "elastic",
  virtuoso: "virtuoso",
  jena: "jena",
  blazegraph: "blazegraph",
} as const;
export function getRedisConnectionInfo(values: Pick<TriplyDBChartSchema, "redis">) {
  const host = values.redis.enabled ? values.redis.clientHost : values.redis.clusterHost;
  const connectionType = values.redis.enabled ? "client" : "cluster";
  return {
    host,
    connectionType,
    port: values.redis.port,
  };
}
export function getHttpProxy(values: Pick<TriplyDBChartSchema, "httpProxy">) {
  return values.httpProxy.ip
    ? `${values.httpProxy.protocol}://${values.httpProxy.ip}:${values.httpProxy.port}`
    : undefined;
}
